@import '~bootstrap/dist/css/bootstrap.min.css';

:root {
  --primary-color: #0078d4;
  --secondary-color: #2b579a;
  --accent-color: #106ebe;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.navbar-brand {
  font-weight: 600;
}

.hero-section {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: white;
  padding: 4rem 0;
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.package-card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.package-card .card-header {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.footer {
  background-color: #f8f9fa;
  padding: 2rem 0;
  margin-top: auto;
} 
.min-vh-50 {
  min-height: 50vh;
}

.fs-lg {
  font-size: 1.25rem;
}

.hero-section {
  background-color: #1a1a1a;
  position: relative;
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.feature-image {
  transition: transform 0.3s ease-in-out;
}

.hover-card:hover .feature-image {
  transform: scale(1.05);
}
.packages-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
}

.hover-card .card-header {
  transition: background-color 0.3s ease;
}

.hover-card:hover .card-header {
  background-color: var(--bs-primary-dark, #0056b3) !important;
}

/* Bootstrap icons için CDN linki */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

/* Fiyat kartları için özel stiller */
.card {
  border-radius: 1rem;
  overflow: hidden;
}

.card-header {
  border-radius: 0 !important;
}

.bi {
  font-size: 1.2rem;
}

.display-5 {
  font-size: 2.5rem;
}
.contact-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
}

/* Form stilleri */
.form-control-lg {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.form-control-lg:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.15);
}

.form-select-lg {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.btn-lg {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

/* Card gölge efekti */
.card {
  transition: transform 0.3s ease-in-out;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

/* İletişim form container */
.contact-form-container {
  position: relative;
  z-index: 1;
  margin-top: -100px;
}
/* src/styles/App.css içine ekleyin ya da güncelleyin */

.contact-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
}

.contact-form-container {
  position: relative;
  margin-top: -50px;
  margin-bottom: 50px;
}

.form-control-lg {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: none;
}

.form-control-lg:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.15);
}

.form-select-lg {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: none;
}

.btn-lg {
  padding: 1rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.contact-info-wrapper {
  height: 100%;
}

.contact-info-item {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-info-item:last-child {
  border-bottom: none;
}

.contact-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

/* Form input hover efektleri */
.form-control:hover, .form-select:hover {
  background-color: #f0f0f0;
}

/* Card hover efekti */
.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

/* Responsive düzenlemeler */
@media (max-width: 991.98px) {
  .contact-form-container {
    margin-top: 0;
  }
  
  .contact-info-wrapper {
    margin-bottom: 2rem;
  }
}
.custom-navbar {
  background-color: #fff;
  transition: all 0.3s ease;
  padding: 0.75rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-scrolled {
  padding: 0.5rem 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.brand-text {
  font-weight: 600;
  font-size: 1.2rem;
  color: #686adb; /* Teams primary color */
}

/* Nav Items */
.nav-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  color: #616161;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.nav-item:hover {
  background-color: #f5f5f5;
  color: #5456b9;
}

.nav-item.active {
  background-color: #7177cd;
  color: white;
}

.nav-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Language Dropdown */
.language-dropdown .dropdown-toggle {
  padding: 0.5rem 1rem;
  color: #616161;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.language-dropdown .dropdown-toggle:hover {
  background-color: #f5f5f5;
  color: #464775;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
  color: #464775;
}

/* Navbar Toggle Button */
.navbar-toggler {
  border: none;
  padding: 0.5rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* Responsive Adjustments */
@media (max-width: 991.98px) {
  .nav-item {
    margin: 0.25rem 0;
  }
  
  .language-dropdown {
    margin: 0.25rem 0;
  }
  
  .navbar-collapse {
    padding: 1rem 0;
  }
}

/* Microsoft Teams inspired color variables */
:root {
  --teams-primary: #464775;
  --teams-secondary: #616161;
  --teams-hover: #f5f5f5;
  --teams-active: #464775;
  --teams-text: #252525;
}

/* Additional hover effects */
.nav-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #464775;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-item:hover::after {
  width: 80%;
}

.nav-item.active::after {
  width: 80%;
  background-color: white;
}

/* Smooth transitions */
.navbar-brand,
.nav-link,
.dropdown-toggle,
.dropdown-item {
  transition: all 0.2s ease-in-out;
}

/* Custom focus states */
.nav-link:focus,
.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

/* Navbar brand hover effect */
.navbar-brand:hover .brand-text {
  color: #6264a7; /* Slightly lighter shade for hover */
}
.container-fluid-custom {
  padding: 0;
  overflow: hidden;
}

/* Hero section styles */
.hero-section,
.about-hero,
.packages-hero,
.contact-hero {
  width: 100vw;
  min-height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
}

/* Content section styles */
.content-section {
  padding: 5rem 0;
}

.content-section-alt {
  background-color: #f8f9fa;
  padding: 5rem 0;
}

/* Image section styles */
.image-section {
  position: relative;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Text content styles */
.text-content {
  padding: 4rem;
}

@media (max-width: 768px) {
  .text-content {
    padding: 2rem;
  }
  
  .image-section {
    min-height: 300px;
  }
}
.min-vh-75 {
  min-height: 75vh;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  font-size: 1.1rem;
  color: #4a4a4a;
}

.hover-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.text-content {
  padding: 4rem;
}

@media (max-width: 768px) {
  .text-content {
    padding: 2rem;
  }
  
  .min-vh-75 {
    min-height: 60vh;
  }
}
.object-fit-cover {
  object-fit: cover;
}

.container-fluid-custom {
  padding: 0;
  overflow: hidden;
}

.hero-section {
  width: 100vw;
  min-height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.content-section {
  padding: 5rem 0;
}

.content-section-alt {
  background-color: #f8f9fa;
  padding: 5rem 0;
}

.image-section {
  position: relative;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
}

.text-content {
  padding: 4rem;
}

.min-vh-75 {
  min-height: 75vh;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  font-size: 1.1rem;
  color: #4a4a4a;
}

.hover-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.object-fit-cover {
  object-fit: cover;
}

@media (max-width: 768px) {
  .text-content {
    padding: 2rem;
  }
  
  .min-vh-75 {
    min-height: 60vh;
  }
  
  .image-section {
    min-height: 300px;
  }
}

.fs-lg {
  font-size: 1.25rem;
}

.feature-image {
  transition: transform 0.3s ease-in-out;
}

.hover-card:hover .feature-image {
  transform: scale(1.05);
}
.about-hero {
  width: 100vw;
  min-height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.value-card {
  background: white;
  border-radius: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.value-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
}

.value-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 123, 255, 0.1);
  border-radius: 50%;
}
.packages-hero {
  width: 100vw;
  min-height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.package-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.package-card:hover {
  transform: translateY(-10px);
}

.package-card.popular {
  border: 2px solid var(--bs-primary);
}

.popular-badge {
  position: absolute;
  top: 1rem;
  right: -2rem;
  background: var(--bs-primary);
  color: white;
  padding: 0.5rem 3rem;
  transform: rotate(45deg);
  font-size: 0.8rem;
  font-weight: bold;
  z-index: 1;
}

.package-title {
  color: var(--bs-primary);
  font-size: 1.5rem;
}

.common-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0;
}

.common-feature-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.accordion-item {
  background: white;
  border-radius: 0.5rem !important;
  overflow: hidden;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.accordion-button {
  font-weight: 500;
  padding: 1.5rem;
}

.accordion-button:not(.collapsed) {
  background-color: var(--bs-primary);
  color: white;
}

.accordion-body {
  padding: 1.5rem;
}

@media (max-width: 768px) {
  .common-features {
    grid-template-columns: 1fr;
  }
}
.contact-hero {
  width: 100vw;
  min-height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.contact-form-container {
  position: relative;
  margin-top: -50px;
}

.input-group-text {
  border: none;
  padding: 0.75rem 1rem;
}

.form-control-lg {
  padding: 0.75rem 1rem;
  border: none;
}

.form-control-lg:focus {
  box-shadow: none;
  background-color: #fff !important;
}

.contact-info-item {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-info-item:last-child {
  border-bottom: none;
}

.contact-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transform: translateY(-3px);
}

@media (max-width: 991.98px) {
  .contact-form-container {
    margin-top: 0;
  }
  
  .contact-info-wrapper {
    margin-top: 2rem;
  }
}

/* Form validation styles */
.was-validated .form-control:valid, 
.form-control.is-valid {
  border-color: #28a745;
  background-image: none;
}
.was-validated .form-control:valid, 
.form-control.is-valid {
  border-color: #28a745;
  background-image: none;
}

.was-validated .form-control:invalid, 
.form-control.is-invalid {
  border-color: #dc3545;
  background-image: none;
}

.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/* Input group hover effects */
.input-group:hover .form-control,
.input-group:hover .input-group-text {
  background-color: #f0f0f0 !important;
}

/* Custom select styling */
.form-select-lg {
  padding: 0.75rem 1rem;
  border: none;
}

.form-select-lg:focus {
  box-shadow: none;
  background-color: #fff !important;
}

/* Card animations */
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-info-wrapper .card:hover {
  transform: translateY(-5px);
}

/* Contact info item hover effect */
.contact-info-item {
  transition: transform 0.3s ease;
}

.contact-info-item:hover {
  transform: translateX(10px);
}

/* Contact icon animations */
.contact-icon {
  transition: all 0.3s ease;
}

.contact-info-item:hover .contact-icon {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

/* Button hover effects */
.btn-lg {
  transition: all 0.3s ease;
}

.btn-lg:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Form feedback messages */
.invalid-feedback {
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Textarea custom styling */
textarea.form-control {
  resize: vertical;
  min-height: 120px;
}

/* Loading spinner styles */
.spinner-border-sm {
  width: 1.2rem;
  height: 1.2rem;
}

/* Social media links custom styles */
.social-links-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
  color: white;
}

/* Responsive adjustments */
@media (max-width: 991.98px) {
  .contact-form-container {
    margin-top: 0;
  }
  
  .contact-info-wrapper {
    margin-top: 2rem;
  }
  
  .form-control-lg,
  .form-select-lg,
  .input-group-text {
    padding: 0.5rem 0.75rem;
  }
  
  .card-body {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .contact-hero {
    min-height: 40vh;
  }
  
  .display-4 {
    font-size: 2.5rem;
  }
  
  .lead {
    font-size: 1.1rem;
  }
  
  .contact-info-item {
    padding: 0.75rem 0;
  }
  
  .social-link {
    width: 35px;
    height: 35px;
  }
}

/* Success message styles */
.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #28a745;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Custom scrollbar for textarea */
textarea.form-control::-webkit-scrollbar {
  width: 8px;
}

textarea.form-control::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

textarea.form-control::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

textarea.form-control::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.terms-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
}

.terms-content h2 {
  color: var(--bs-primary);
  font-weight: 600;
}

.terms-content p {
  color: #4a4a4a;
  font-size: 1.1rem;
}

.terms-content strong {
  color: #2b2b2b;
}

.terms-content ul li {
  font-size: 1.1rem;
  color: #4a4a4a;
}

.terms-content .bi {
  font-size: 1.2rem;
}

.terms-content section {
  transition: transform 0.3s ease;
}

.terms-content section:hover {
  transform: translateX(10px);
}

@media (max-width: 768px) {
  .terms-hero {
    min-height: 30vh;
  }
  
  .terms-content p,
  .terms-content ul li {
    font-size: 1rem;
  }
}
.privacy-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
}

.privacy-content h2 {
  color: var(--bs-primary);
  font-weight: 600;
}

.privacy-content p {
  color: #4a4a4a;
  font-size: 1.1rem;
  line-height: 1.8;
}

.privacy-section {
  transition: transform 0.3s ease;
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05);
}

.privacy-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 2rem rgba(0,0,0,0.1);
}

.privacy-icon-wrapper {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--bs-primary-rgb), 0.1);
  border-radius: 50%;
  flex-shrink: 0;
}

.privacy-icon {
  color: var(--bs-primary);
  font-size: 28px;
}

.security-features li {
  font-size: 1.1rem;
  color: #4a4a4a;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin-bottom: 1rem !important;
  transition: transform 0.3s ease;
}

.security-features li:hover {
  transform: translateX(10px);
  background: #f0f0f0;
}

.privacy-content a {
  color: var(--bs-primary);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.privacy-content a:hover {
  color: var(--bs-primary-dark, #0056b3);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-hero {
    min-height: 30vh;
  }
  
  .privacy-section {
    padding: 1.5rem;
  }
  
  .privacy-icon-wrapper {
    width: 50px;
    height: 50px;
  }
  
  .privacy-icon {
    font-size: 24px;
  }
  
  .privacy-content p,
  .security-features li {
    font-size: 1rem;
  }
}
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: #4a4a4a;
}

.dropdown-item .nav-icon {
  margin-right: 0.5rem;
  font-size: 1.1rem;
}

.dropdown-item:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-primary);
}

.nav-item .dropdown-toggle::after {
  margin-left: 0.5rem;
}

#legal-nav-dropdown {
  padding-right: 1rem;
}

@media (max-width: 991.98px) {
  .dropdown-menu {
    border: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-item {
    padding: 0.75rem 1rem;
  }
  
  #legal-nav-dropdown {
    padding-right: 0;
  }
}
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: #4a4a4a;
}

.dropdown-item .nav-icon {
  margin-right: 0.5rem;
  font-size: 1.1rem;
}

.dropdown-item:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-primary);
}

.nav-item .dropdown-toggle::after {
  margin-left: 0.5rem;
}

#legal-nav-dropdown {
  padding-right: 1rem;
}

@media (max-width: 991.98px) {
  .dropdown-menu {
    border: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-item {
    padding: 0.75rem 1rem;
  }
  
  #legal-nav-dropdown {
    padding-right: 0;
  }
}
.navbar {
  background-color: #fff !important;
  padding: 0.5rem 1rem;
}

.navbar-brand .brand-text {
  color: var(--teams-primary);
  font-weight: 600;
}

/* Nav item stilleri */
.nav-item {
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  color: #252525 !important;
  transition: all 0.2s ease;
}

.nav-item:hover {
  background-color: var(--teams-primary);
  color: white !important;
}

.nav-item:hover .nav-icon,
.nav-item:hover span {
  color: white !important;
}

/* Dropdown stilleri */
.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: #252525;
  transition: all 0.2s ease;
}

.dropdown-item:hover {
  background-color: var(--teams-primary);
  color: white;
}

.dropdown-item:hover .nav-icon {
  color: white;
}

/* İkonlar için stil */
.nav-icon {
  color: var(--teams-primary);
  margin-right: 0.5rem;
  transition: all 0.2s ease;
}

/* Butonlar için Teams stili */
.btn-primary {
  background-color: var(--teams-primary);
  border-color: var(--teams-primary);
  color: white;
}

.btn-primary:hover {
  background-color: var(--teams-hover);
  border-color: var(--teams-hover);
  color: white;
}

/* Dropdown menu stilleri */
.dropdown-menu {
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* Aktif nav item için stil */
.nav-item.active {
  background-color: var(--teams-primary);
  color: white !important;
}

.nav-item.active .nav-icon,
.nav-item.active span {
  color: white !important;
}

/* Navbar toggle button stilleri */
.navbar-toggler {
  border: none;
  padding: 0.5rem;
  color: var(--teams-primary);
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

/* Dropdown toggle stilleri */
.dropdown-toggle::after {
  margin-left: 0.5rem;
}

/* Responsive düzenlemeler */
@media (max-width: 991.98px) {
  .nav-item {
    margin: 0.25rem 0;
  }
  
  .navbar-collapse {
    padding: 1rem 0;
  }
  
  .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
  
  .dropdown-item {
    color: #252525;
    padding: 0.5rem 1rem;
  }
}
.terms-hero,
.privacy-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
}

.terms-content,
.privacy-content {
  font-size: 1.1rem;
  line-height: 1.8;
}

.section-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--bs-primary-rgb), 0.1);
  border-radius: 50%;
  margin-bottom: 2rem;
}

.terms-content section,
.privacy-content section {
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.terms-content section:hover,
.privacy-content section:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 2rem rgba(0,0,0,0.1);
}

.list-unstyled li {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.list-unstyled li:hover {
  transform: translateX(10px);
  background: #f0f0f0;
}

@media (max-width: 768px) {
  .terms-hero,
  .privacy-hero {
    min-height: 30vh;
  }
  
  .terms-content,
  .privacy-content {
    font-size: 1rem;
  }
  
  .section-icon {
    width: 60px;
    height: 60px;
  }
  
  .terms-content section,
  .privacy-content section {
    padding: 1.5rem;
  }
}
.terms-hero {
  background-color: #1a1a1a;
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
 }
 
 .terms-content {
  font-size: 1.1rem;
  line-height: 1.8;
 }
 
 .section-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--bs-primary-rgb), 0.1);
  border-radius: 50%;
  margin-bottom: 2rem;
 }
 
 .terms-content section {
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
 }
 
 .terms-content section:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 2rem rgba(0,0,0,0.1);
 }
 
 .list-unstyled li {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
 }
 
 .list-unstyled li:hover {
  transform: translateX(10px);
  background: #f0f0f0;
 }
 
 @media (max-width: 768px) {
  .terms-hero {
    min-height: 30vh;
  }
  
  .terms-content {
    font-size: 1rem;
  }
  
  .section-icon {
    width: 60px;
    height: 60px;
  }
  
  .terms-content section {
    padding: 1.5rem;
  }
 }